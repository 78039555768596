
import PhotoSwipeLightbox from 'photoswipe/dist/photoswipe-lightbox.esm.js';

const lightbox = new PhotoSwipeLightbox({
    gallery: '#gallery',
    children: 'a',
    bgOpacity: 0.8,
    closeTitle: 'Fermer la modale',
    zoomTitle: 'Agrandir la photo',
    arrowPrevTitle: 'Aller à la photo précédente',
    arrowNextTitle: 'Aller à la photo suivante',
    pswpModule: () => import('photoswipe/dist/photoswipe.esm.js'),
});

lightbox.on('uiRegister', function() {
    lightbox.pswp.ui.registerElement({
        name: 'custom-caption',
        order: 9,
        isButton: false,
        appendTo: 'root',
        html: 'Caption text',
        onInit: (el, pswp) => {
            lightbox.pswp.on('change', () => {
                const currSlideElement = lightbox.pswp.currSlide.data.element;
                let captionHTML = '';
                if (currSlideElement) {
                    captionHTML = currSlideElement.querySelector('img').getAttribute('alt');
                }
                el.innerHTML = captionHTML || '';
            });
        }
    });
});

lightbox.init();

